.custom-container {
  padding: 40px;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-header h1 {
  font-size: 22px;
}

.custom-header h2 {
  font-size: 20px;
}

.custom-header img {
  width: 100px;
}

.form-double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
}

.form-single {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px 20px;
}

.custom-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px 0;
}

.custom-input-group input,
.custom-input-group select,
.custom-input-group textarea {
  border-radius: 3px;
  border: 1px solid #c0c0c0;
  padding: 8px 12px;
  width: 100%;
  background-color: #fff;
}

.radio-group {
  display: flex;
  width: 300px;
}

.radio-group .custom-input-group,
.checkbox-group .custom-input-group {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.radio-group .custom-input-group label,
.checkbox-group .custom-input-group label {
  margin-bottom: 0;
  margin-left: 5px;
}

.radio-group input[type="radio"],
.checkbox-group input[type="checkbox"] {
  width: auto;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.custom-tooltip .custom-tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 30px;
}

.custom-tooltip:hover .custom-tooltip-text {
  visibility: visible;
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
  margin: 30px 0 20px 0;
}

.custom-input-group textarea {
  resize: none;
  height: 100px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.note {
  margin: 20px 0;
}

.custom-button {
  font-size: 16px;
  background-color: #ce9cf0;
  border: none;
  padding: 8px 16px;
  color: #fff;
  border-radius: 3px;
}

.custom-button:disabled,
.custom-input-group[disabled] {
  opacity: 0.5;
}

.organization-info {
  text-transform: capitalize;
}

.list {
  padding-left: 40px;
}

.list li {
  display: list-item;
}
